import * as React from 'react';
import { Text, MainContainer, SEO } from '~/components';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FaInstagram } from 'react-icons/fa';

const Title = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Section = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  '&:first-of-type': {
    marginTop: theme.spacing(0),
  },
}));

const SectionText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const linkItem = (theme) =>
  css({
    color: 'inherit',
    textDecoration: 'none',
    color: theme.colors.sand,
  });

function Contact() {
  return (
    <>
      <SEO
        title="Kontakt"
        description="Kontaktiere uns für spezielle Wünsche oder sonstige Anfragen."
      />
      <MainContainer
        css={(theme) => ({
          alignItems: 'center',
          textAlign: 'center',
          color: theme.colors.sand,
        })}
      >
        <h1
          css={(theme) => ({
            color: theme.colors.rose,
            textTransform: 'uppercase',
            textAlign: 'center',
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(2),
          })}
        >
          Kontakt
        </h1>
        <Section>
          <h2>Lüt em Leo a</h2>
          <a href="tel:0764219599" css={linkItem}>
            076 421 95 99
          </a>
        </Section>
        <Section>
          <h2>Schrib eus</h2>
          <a href="mailto:bar@nahschub.ch" css={linkItem}>
            bar@nahschub.ch
          </a>
        </Section>
        <Section>
          <h2>Da au erreichbar</h2>
          <a
            href="https://www.instagram.com/nahschub/"
            target="_blank"
            css={(theme) => ({
              border: `2px solid ${theme.colors.sand}`,
              borderRadius: 1000,
              width: 44,
              height: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              transition: '0.5s',
              color: theme.colors.sand,
              '&:hover': {
                backgroundColor: theme.colors.sand,
                color: theme.colors.green,
              },
            })}
          >
            <FaInstagram size={20} />
          </a>
        </Section>
      </MainContainer>
    </>
  );
}

export default Contact;
